import React from 'react';
import Layout from '../components/layout';
import PrivacySection from '../components/termsSection';
import SEO from '../components/seo';
import Suscribe from "../components/suscribe";

const Privacy = () => (
  <Layout>
    <SEO
      title="Providing a safe environment to express yourself"
      description="Freely is creating a safe and accessible community of trusted listeners offering emotional support for people to express themselves openly and anonymously."
    />
    <div id="index" className="">
      <PrivacySection />
      <Suscribe/>
    </div>
  </Layout>
);

export default Privacy;
